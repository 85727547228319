<script lang="ts">
    import type { BasicContent } from "$lib/domain/basic-content.interface";
    import { icons } from '$lib/config/svg-base';
    import { technologyIcons } from '$lib/config/svg-technologyIcons';

    let iconsBase = { ...icons, ...technologyIcons };
    export let service: BasicContent;
    const itemIcon = service ? iconsBase[service.iconName] : null;
</script>

{#if !!service.url?.length}
	<article class="lincked">
		<a href="{service.url}">
			<div>
				<div class="service-card-icon">
					{@html itemIcon}
				</div>

				<h3>{service.title}</h3>

				<p>
					{@html service.content}
				</p>

				<div class="spacer"></div>
			</div>
		</a>
	</article>
{:else}
	<article>
		<div>
			<div class="service-card-icon">
				{@html itemIcon}
			</div>

			<h3>{service.title}</h3>

			<p>
				{@html service.content}
			</p>

			<div class="spacer"></div>
		</div>
	</article>
{/if}

<style lang="scss">
  @import '$styles/vars';

  .lincked {
    &:hover {
      border: 4px solid $green;
    }
  }

  article {
    padding: 36px 24px;
    background-color: $light-grey;
    border: 4px solid transparent;
    border-radius: 12px;

    a {
      color: $main-black;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .service-card-icon {
      margin-bottom: 24px;
    }

    h3 {
      text-align: center;
      color: $main-black;
      font-size: 18px;
      margin: 0;
      font-weight: 700;
      line-height: 22px;
    }

    p {
      text-align: center;
      margin-top: 12px;
    }

    .spacer {
      height: 100%;
    }
  }
</style>
