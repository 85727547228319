<script lang="ts">
	import { swipe } from 'svelte-gestures';
	import { onMount } from 'svelte';
	import { CarouselHelper } from '$lib/helpers/swipe.helper';
	import ServiceCard from './ServiceCard.svelte';
	import type { BasicContent } from "$lib/domain/basic-content.interface";

	export let data: BasicContent[] = [];
	export let title: string | '' = 'Our services';
	export let description: string | undefined = '';

	let activeIndex = 0;
	const maxCount = data.length;
	const carouselHelper = CarouselHelper.getInstance(maxCount);

	onMount(() => {
		if (!window.matchMedia('(max-width: 1400px)').matches) {
			return;
		}

		carouselHelper.resetInterval();
		carouselHelper.indexStore.subscribe((index) => (activeIndex = Number(index)));
	});
</script>

<div class="container">
	<div class="section-title">
		<h2>{@html title}</h2>
	</div>

	{#if !!description}
		<p class="h5">{@html description}</p>
	{/if}

	<div class="services">
		{#each data as service}
			<ServiceCard service="{service}" />
		{/each}
	</div>

	<div
		class="carousel"
		use:swipe="{{ timeframe: 300, minSwipeDistance: 60, touchAction: 'pan-y' }}"
		on:swipe="{carouselHelper.handleSwipe}">
		{#each data as service, i}
			<div class="carousel-item" class:active="{activeIndex === i}">
				<ServiceCard service="{service}" />
			</div>
		{/each}
	</div>

	<ol class="carousel-indicators">
		{#each Array(maxCount) as _, i}
			<li
				on:click="{() => carouselHelper.select(i)}"
				on:keydown="{() => carouselHelper.select(i)}"
				class:active="{i === activeIndex}">
				<div class="carousel-bullet">•</div>
			</li>
		{/each}
	</ol>
</div>

<style lang="scss">
	@import '$styles/vars';

	.services {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: 24px;
		margin-top: 48px;
	}

	.carousel,
	.carousel-indicators {
		box-sizing: border-box;
		display: none;
	}

	h2 {
		color: $main-black;

	}

	.h5 {
		max-width: 720px;

	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
		.services {
			grid-gap: 20px;
		}
	}

	@media (max-width: $portrait-phone-size) {
		.services {
			display: none;
		}

		.carousel,
		.carousel-indicators {
			display: flex;
			width: 100%;

		}
	}
</style>
